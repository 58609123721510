import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FormGroup } from 'reactstrap';
import { deleteAdmin, getAdmin, saveAdmin } from './service';
import { getReadableDate } from '../../utility';
import { confirmAlert } from 'react-confirm-alert';
import EmployeeDropdown from '../../CompanyApp/ModuleSetup/Dropdown/EmployeeDropdown';
import EmployeeListColumn from '../../CompanyApp/Employee/employeeListColumn';
import EmployeeDropDownByAdmin from '../../CompanyApp/ModuleSetup/Dropdown/EmployeeDropDownByAdmin';

toast.configure();
export default class AdminForm extends Component {
    _isMounted = false;

    constructor(props) {
        super(props)
        const company = this.props.company || {};
        this.state = {
            companyId: company.id,
            company : company,
            editable: true,
            employeeId: '',
            admins: [],
            adminData: {}
        };
    }

        componentDidMount() {
            this._isMounted = true;
            this.fetchList();
        }
        componentDidUpdate(prevProps) {
            if (this.props.company !== prevProps.company) {
                this.setState({
                    company:this.props.company,
                })
            }
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        fetchList = () => {
            getAdmin(this.state.companyId)
                .then(res => {
                    if (this._isMounted) {
                        if (res.status === 'OK') {
                            this.setState({
                                admins: res.data,
                            });
                        } else {
                            console.log("Error: " + res.error);
                        }
                    }
                })
                .catch(error => {
                    console.log("Error: " + error);
                });
        };

        save = () => {
            saveAdmin(this.state.employeeId, this.state.companyId)
                .then(res => {
                    if (this._isMounted) {
                        this.fetchList();
                        if (res.status === "OK") {
                            toast.success(res.message);
                            this.setState({
                                employeeId: '',
                            });
                        } else {
                            toast.error(res.message);
                        }
                    }
                })
                .catch(err => {
                    toast.error("Please select the Employee to add Owner/Admin");
                });
        }

        handleEmployeeIdChange = (e) => {
            const newValue = e.target.value;
            this.setState({ employeeId: newValue });
        }

        delete = (empId) => {
            const companyId = this.state.companyId;
            confirmAlert({
                title: `Remove Owner/Admin`,
                message: 'Are you sure, you want to remove this Owner/Admin?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteAdmin(empId, companyId).then(res => {
                            if (res.status == "OK") {
                                toast.success(res.message);
                                this.fetchList();
                            } else {
                                toast.error(res.message)
                            }
                        })
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        }
        render() {
            const { editable, employeeId, admins } = this.state;
            return (
                <>
                    <div className="page-container content container-fluid">
                        {/* Page Header */}
                        <div className="tablePage-header">
                            <div className="row pageTitle-section">
                                <div className="col">
                                    <h3 className="tablePage-title">Manage Company Admin </h3>
                                </div>
                            </div>
                        </div>

                        {/* /Page Header */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.state.adminData}
                                        onSubmit={this.save}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            setFieldValue,
                                            setSubmitting
                                            /* and other goodies */
                                        }) => (
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <FormGroup>
                                                            <label> Select Employee
                                                                <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <Field readOnly={editable} name="employeeId" className="form-control"
                                                                render={field => {
                                                                    return <EmployeeDropDownByAdmin
                                                                        nodefault={false}
                                                                        onChange={this.handleEmployeeIdChange}
                                                                        value={this.state.employeeId}
                                                                        companyId={this.state.companyId}
                                                                    ></EmployeeDropDownByAdmin>
                                                                }}
                                                            ></Field>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-6" style={{ paddingBlock: '32px' }}>
                                                        <input type="submit" className="btn btn-primary" value="Save" />
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                        }
                                    </Formik>

                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <div className="expireDocs-table">
                                                <table className="table">
                                                    <thead >
                                                        <tr style={{ background: '#c4c4c4' }}>
                                                            <th>#</th>
                                                            <th>Admin Name</th>
                                                            <th>Created On</th>
                                                            <th>Created By</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {admins.map((item, index) => (
                                                            <tr key={`${item.empId}_${index}`} className="table-row">
                                                                <td className="table-column">{index + 1}</td>
                                                                <td className="table-column">
                                                                    <EmployeeListColumn
                                                                        key={item.empId}
                                                                        id={item.empId}
                                                                        name={`${item.employeeName}`}
                                                                        employeeId={item.employeeId}
                                                                    />
                                                                </td>
                                                                <td className="table-column">{getReadableDate(item.createdOn)}</td>
                                                                <td className="table-column">{item.createdBy}</td>
                                                                <td className="table-column">
                                                                    <div className="dropdow">
                                                                        <>
                                                                            <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                                <i className="las la-bars"></i>
                                                                            </a>
                                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                                <a className="dropdown-item" href="#" onClick={() => {
                                                                                    this.delete(item.empId);
                                                                                }}>
                                                                                    <i className="fa fa-user-times m-r-5"></i> Remove </a>
                                                                            </div>
                                                                        </>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </>
            )
        }
    }
