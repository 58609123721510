// import 'Assets/css/font-awesome.min.css';
import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
//Error Page
import Error404 from '../MainPage/Pages/ErrorPage/error404';
import Error500 from '../MainPage/Pages/ErrorPage/error500';
import uicomponents from '../MainPage/UIinterface/components';
import ApplyJobs from './ApplyJob';
import { authService } from './authService';
import ForgotPassword from './forgotpassword';
import LockScreen from './lockscreen';
// We will create these two pages in a moment
//Authendication
import LoginPage from './loginpage';
import AutoLoginPage from './autologin'
import OTPPage from './OTPPage';
import RegistrationPage from './RegistrationPage';
import chatlayout from './Sidebar/chatlayout';
//Main App
import DefaultLayout from './Sidebar/DefaultLayout';
import Emaillayout from './Sidebar/emaillayout';
import Settinglayout from './Sidebar/Settinglayout';
import Tasklayout from './Sidebar/tasklayout';
import { getFavIcon, getPrimaryColor, getPrimaryGradientColor, getMenuBgColor, getOtpRequired } from '../utility';
import ForgotPasswordReset from './ResetPassword';
import JobResponse from '../CompanyApp/Hire/hApplicants/hApplicantForm/JobResponse';
import AccessDenied from '../MainPage/Main/Dashboard/AccessDenied';
import JobCandidateFormIndex from '../CompanyApp/Hire/hApplicants/hApplicantForm/JobCandidateFormIndex';
import CandidateInfoForm from '../CompanyApp/Onboarding/Candidateinfo/form';

function getFaviconEl() {
    return document.getElementById("favicon");
}
function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)';
    }
    throw new Error('Bad Hex');
}
function setAppTheme(pc, pgc, mbc) {
    console.log(pc, pgc, mbc);
    document.body.style.setProperty('--primary-gradient-color', pgc);
    document.body.style.setProperty('--primary-color', pc)
    document.body.style.setProperty('--light-bg-color', hexToRgbA(pc))
    document.body.style.setProperty('--menu-background-color', mbc)
}
class App extends Component {
    constructor(props) {
        super(props);
        let user = undefined;
        let menu = undefined;
        try {
            if (this.props.user) {
                let jsonUserData = JSON.parse(this.props.user);
                user = jsonUserData;
                menu = jsonUserData.menu;
            }
        } catch (error) {
            console.log(error);
        }
        this.state = {
            user,
            menu
        }
    }


    componentWillMount() {
        const { location } = this.props;

        getFaviconEl().setAttribute("href", getFavIcon());
        setAppTheme(getPrimaryColor(), getPrimaryGradientColor(), getMenuBgColor());
        if (location.pathname.includes("login") || location.pathname.includes("register") || location.pathname.includes("forgot-password")
            || location.pathname.includes("otp") || location.pathname.includes("lockscreen")) {
            $('body').addClass('account-page');
        } else if (location.pathname.includes("error-404") || location.pathname.includes("error-500")) {
            $('body').addClass('error-page');
        }
    }
    render() {
        const { location, match, user } = this.props;
        if (user === null && !location.pathname.includes("login") && !location.pathname.includes("forgot-password") && !location.pathname === '/hire/candidateinfo/:id/:sourcetype') {
            return (<Redirect to={'/login'} />);
        }
        else if (getOtpRequired() && !location.pathname.includes("/otp")) {
            return (<Redirect to={'/otp'} />);
        }
        else if (location.pathname === '/') {
            return (<Redirect to={'/app/main/dashboard'} />);
        }
        return (
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/auto-login" component={AutoLoginPage} />
                <Route path="/forgot-password/reset" component={ForgotPasswordReset} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route path="/register" component={RegistrationPage} />
                <Route path="/otp" component={OTPPage} />
                <Route path="/lockscreen" component={LockScreen} />
                <Route path="/applyjob" component={ApplyJobs} />
                <Route path="/settings" component={Settinglayout} />
                <Route path="/tasks" component={Tasklayout} />
                <Route path="/email" component={Emaillayout} />
                <Route path="/conversation" component={chatlayout} />
                <Route path="/ui-components" component={uicomponents} />
                <Route path="/error-404" component={Error404} />
                <Route path="/error-500" component={Error500} />
                <Route path={`/access-denied`} component={AccessDenied} />
                <Route exact path="/hire/candidateinfo/:id/:sourcetype" component={JobCandidateFormIndex} />
                <Route exact path="/externalofferlettercandidateinfo" component={CandidateInfoForm} />
                <Route exact path="/successMessage" component={JobResponse} />

                <Route path="/app" render={(props) => {
                    return <DefaultLayout logout={this.props.logout} menu={this.state.menu}></DefaultLayout>;
                }} />
            </Switch>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}
const mapDispatchToProps = (dispatch) => ({

    logout: () => dispatch(authService.logout())

})
export default connect(mapStateToProps, mapDispatchToProps)(App);