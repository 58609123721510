/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EmployeeDetail from './detail';


const EmployeeDetailSetupRoute = ({ match }) => {
   return (
      <Switch>
         <Route path={`${match.url}/:id`} component={EmployeeDetail} />
      </Switch>
   )
};

export default EmployeeDetailSetupRoute;
