import axios, { Axios } from "axios";
import { getTokenCookie, permissionCheck } from './utility';
import { trackPromise } from 'react-promise-tracker';
import { toast } from "react-toastify";

const token = getTokenCookie();
const headers = {
    "Authorization": `Bearer ${token}`
};
let config = {
    headers: {
        'Content-Type': 'application/json'
    }
};
export const getAPIUrl = () => {
    return "https://hrmsservice.workplus-hrms.com/service"
}
const http = axios.create({
    baseURL: getAPIUrl(),
    headers: headers
});
const surveyHttp = axios.create({
    baseURL: "https://hrmsservice.workplus-hrms.com/survey-service",
    headers: headers
});

export const getWithAuth = (path) => {
    return trackPromise(http.get(path).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }).catch(err => {
        console.log({ err })
        if (err.response.status == 403)
            window.location.href = "/login";
        else
            toast.error(err.message);
    }));
}
export const getWithAuthDashboard = (path) => {
    return (http.get(path).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }).catch(err => {
        console.log({ err })
        if (err.response.status == 403)
            window.location.href = "/login";
        else
            toast.error(err.message);
    }));
}
export const getWithAuthSurvey = (path) => {
    return trackPromise(surveyHttp.get(path).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }).catch(err => {
        console.log({ err })
        if (err.response.status == 403)
            window.location.href = "/login";
        else
            toast.error(err.message);
    }));
}

export const getBlobWithAuth = (path) => {
    return trackPromise(http.get(path, {
        responseType: 'blob'
    }).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }).catch(err => {
        if (err.response.status == 403)
            window.location.href = "/login";
        else
            toast.error(err.message);
    }));
}

export const postWithAuth = (path, body, isMultipart = false) => {
    let data = body;
    if (isMultipart) {
        config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        data = new FormData();
        Object.keys(body).forEach(key => {
            data.append(key, body[key]);
        });
    } else {
        config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return trackPromise(http.post(path, data, config).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}
export const postWithAuthSurvey = (path, body, isMultipart = false) => {
    let data = body;
    if (isMultipart) {
        config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        data = new FormData();
        Object.keys(body).forEach(key => {
            data.append(key, body[key]);
        });
    } else {
        config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return trackPromise(surveyHttp.post(path, data, config).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}
export const putWithAuth = (path, body, isMultipart = false) => {
    let data = body;
    if (isMultipart) {
        config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        data = new FormData();
        Object.keys(body).forEach(key => {
            data.append(key, body[key]);
        });
    } else {
        config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return trackPromise(http.put(path, data, config).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}

export const putWithAuthSurvey = (path, body, isMultipart = false) => {
    let data = body;
    if (isMultipart) {
        config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        data = new FormData();
        Object.keys(body).forEach(key => {
            data.append(key, body[key]);
        });
    } else {
        config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    return trackPromise(surveyHttp.put(path, data, config).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}
export const deleteWithAuth = (path, body) => {
    return trackPromise(http.delete(path, { data: body }).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}
export const deleteWithAuthSurvey = (path, body) => {
    return trackPromise(surveyHttp.delete(path, { data: body }).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}

export const patchWithAuth = (path, body) => {
    return trackPromise(http.patch(path, body).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}

export const patchWithAuthSurvey = (path, body) => {
    return trackPromise(surveyHttp.patch(path, body).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}

export const fileDownload = (downloadId, referenceId, type, fileName) => {

    http.get(`/download?downloadId=${downloadId}&referenceId=${referenceId}&type=${type}`, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}
export const downloadPayslipCsv = (salaryMonth) => {
    http.get(`/payslip/download-csv?salaryMonth=${salaryMonth}`, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${salaryMonth}paylisp.csv`);
            document.body.appendChild(link);
            link.click();
        });
};
export const downloadPayslipSif = (salaryMonth) => {
    http.get(`/payslip/download-sif?salaryMonth=${salaryMonth}`, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${salaryMonth}paylisp.sif`);
            document.body.appendChild(link);
            link.click();
        });
};
export const employeeProfilePhotoURL = (id) => {
    return new Promise((resolve, reject) => {
        const cacheBuster = getTokenCookie() ? "" : getTokenCookie().slice(-5);
        http
            .get(`/media/employee-profile?id=${id}&cache=${cacheBuster}`, { responseType: 'blob' })
            .then((response) => {
                const imageURL = URL.createObjectURL(response.data);
                resolve(imageURL);
            })
            .catch((error) => {
                console.log("No Profile Photo: " + id);
                reject(error);
            });
    });
};
export const postSSO = (path, token) => {

    config = {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        }
    }
    return trackPromise(http.post(path, null, config).then(res => {
        if (permissionCheck(res.data)) {
            return Promise.resolve(res);
        }
    }));
}

export const downloadAttendanceTemplate = (fileName) => {
    return new Promise((resolve, reject) => {
        http.get(`/import-job/template?fileName=${fileName}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                resolve();
            })
            .catch((error) => {
                console.error('Error downloading template:', error);
                reject(error);
            });
    });
};
