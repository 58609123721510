import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SurveyParticipantList from './manageSurvey/SurveyParticipant/surveyParticipant';
import ManageActionsLanding from './manageSurvey/manage';
import SurveyReport from './manageSurvey/surveyReport/report';
import ViewResponse from './manageSurvey/surveyReport/viewResponse';
import { getUserType, verifyOrgLevelViewPermission } from '../../utility';
import Landing from './manageSurvey/landing';
import Template from './manageSurvey/Template';
import AccessDenied from '../../MainPage/Main/Dashboard/AccessDenied';

const CommonSurveyRoutes = ({ match }) => (
  <>
    <Route path={`${match.url}/invitee/:id`} component={SurveyParticipantList} />
    <Route path={`${match.url}/manage/`} component={ManageActionsLanding} />
    <Route path={`${match.url}/report/view-response/`} component={ViewResponse} />
    <Route exact path={`${match.url}/report`} component={SurveyReport} />
    {verifyOrgLevelViewPermission("Survey") &&
    <Route exact path={match.url} component={() => (
      (getUserType() === 'COMPANY_ADMIN'|| verifyOrgLevelViewPermission("Survey")) ? <Landing /> :
        getUserType() === 'SUPER_ADMIN' ? <div className='adminInsidePageDiv'>
          <Template />
        </div> :
          null
    )} />}
    {!verifyOrgLevelViewPermission("Survey") && <AccessDenied></AccessDenied>}
  </>
);

const SurveySetupRoute = ({ match }) => {
  console.log(match);
  return (
    <Switch>
      <Route path="/app/company-app/survey" render={(props) => <CommonSurveyRoutes {...props} />} />

      <Route path="/app/admin-app/manage-survey" render={(props) => <CommonSurveyRoutes {...props} />} />
    </Switch>
  );
};
export default SurveySetupRoute;