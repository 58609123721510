import { Button } from '@mui/material'
import React, { Component } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { GiStairsGoal } from 'react-icons/gi';
import { TbClockExclamation } from 'react-icons/tb';
import { FcCalendar } from 'react-icons/fc';
import Chart from "react-apexcharts";
import { CartesianGrid, Legend, Line, LineChart,Tooltip, XAxis, YAxis } from 'recharts';
import { Tooltip as AntdTooltip } from 'antd';
import { VscFeedback } from "react-icons/vsc";

import { MdOutline360 } from 'react-icons/md';
import { Modal } from 'antd';
import 'antd/dist/antd.css';

const data = [
    { date: 'JAN-2024', progress: 2 },
    { date: 'FEB-2024', progress: 5 },
    { date: 'MAR-2024', progress: 15 },
    { date: 'APR-2024', progress: 25 },
    { date: 'MAY-2024', progress: 30 },
    { date: 'JUN-2024', progress: 35 },
    { date: 'JUL-2024', progress: 35 },
    { date: 'AUG-2024', progress: 35 },
    { date: 'SEP-2024', progress: 45 },
    { date: 'OCT-2024', progress: 48 },
    { date: 'NOV-2024', progress: 51 },
    { date: 'DEC-2024', progress: 56 }
];

const progressData = [
    { range: '≥ 80%', value: 20, color: '#4CAF50', count: 2 },
    { range: '60 - 80%', value: 40, color: '#9ACD32', count: 4 },
    { range: '40 - 60%', value: 10, color: '#FFD700', count: 1 },
    { range: '20 - 40%', value: 30, color: '#FFA500', count: 3 },
    { range: '0 - 20%', value: 20, color: '#FF4C4C', count: 2 }
];


export default class MyPerformDashboard extends Component {
    constructor(props) {
        super(props);

        const data = [
            { 'Completed': 2 },
            { 'On Track': 1 },
            { 'Needs Attention': 3 },
            { 'At Risk': 4 },
            { 'No Status': 2 },
        ];

        // Extract labels and series from data
        const labels = data.map(item => Object.keys(item)[0]);
        const series = data.map(item => Object.values(item)[0]);
        const total = series.reduce((acc, value) => acc + value, 0);

        this.state = {
            series: series,
            isModalVisible: false,
            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                },
                tooltip: {
                    enabled: true,
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        const value = series[seriesIndex];
                        const percentage = ((value / total) * 100).toFixed(1);
                        const label = w.config.labels[seriesIndex];
                        return (
                            `<div class="arrow_box">
                      <span>${label}: ${percentage}%</span>
                    </div>`
                        );
                    }
                },
                dataLabels: {
                    enabled: false
                },
                labels: labels,
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " = " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom',
                            position: 'absolute',
                            left: 'auto',
                            top: '7px',
                            right: '5px',
                        }
                    }
                }]
            },

        };

    };


    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };



    render() {


        return (
            <div style={{ paddingBottom: '30px' }}>
                <div className='d-flex' >
                    <div className='myCardsGrid' >
                        <div className="ml-0 myDashCardsBody row">
                            <div className="col p-0">
                                <div className='mygrid-div'>
                                    <GiStairsGoal style={{ color: '#4DC2DD' }} className='mydashAvarageIcon m-2' size={35} />
                                    <p className='mb-1'>Total Goals Count</p>
                                    <span style={{ fontSize: '14px', fontWeight: 700 }}>12</span>
                                </div>
                            </div>
                            <div className="col p-0">
                                <div className='mygrid-div'>
                                    <CircularProgressbar
                                        className='punctualityChart'
                                        value={80}
                                        strokeWidth={50}
                                        styles={buildStyles({
                                            strokeLinecap: 'butt',
                                            pathTransitionDuration: 0.5,
                                            pathColor: '#45C56D',
                                            trailColor: '#dbdbdb',
                                            backgroundColor: '#3e98c7',
                                        })}
                                    />
                                    <p className='mb-1'>Average Progress</p>
                                    <span style={{ fontSize: '14px', fontWeight: 700 }}>70%</span>

                                </div>
                            </div>
                            <div className="col p-0">
                                <div className='mygrid-div'>
                                    <TbClockExclamation style={{ color: '#ff7081' }} className='mydashAvarageIcon m-2' size={35} />
                                    <p className='mb-1'>Overdue Goals</p>
                                    <span style={{ fontSize: '14px', fontWeight: 700 }}>4</span>
                                </div>
                            </div>
                            <div className="col p-0">
                                <div style={{ border: 'none' }} className='mygrid-div'>
                                    <FcCalendar style={{ color: '#f88535' }} className='mydashAvarageIcon m-2' size={35} />
                                    <p className='mb-1'>Upcoming Goals</p>
                                    <span style={{ fontSize: '14px', fontWeight: 700 }}>4</span>
                                </div>
                            </div>

                        </div>
                        <div className="mt-4 ml-0 myDashCardsBody2 row">
                            <div className="col p-0">
                                <div className='mygrid-div'>
                                    <div className='permissionBtnGrid d-flex'>
                                        <VscFeedback style={{ color: '#2e5984', margin: '10px' }} className='mydashAvarageIcon' size={40} />
                                        <p style={{ alignContent: 'center' }} className='m-2'> Feedback from peers, <br /> Manager and Team</p>
                                        <Button variant="contained" sx={{ placeSelf: 'center', height: '31px', textTransform: 'none' }} size="small" >Request Feedback</Button>
                                    </div>

                                </div>
                            </div>
                            <div className="col p-0">
                                <div style={{ borderRight: 'none' }} className='mygrid-div'>
                                    <div className='permissionBtnGrid d-flex'>
                                        <MdOutline360 style={{ color: '#8c5fe8', margin: '10px' }} className='mydashAvarageIcon' size={40} />
                                        <p style={{ alignContent: 'center' }} className='m-2'>Continues Feedback</p>
                                        <Button onClick={() => this.handleNavigateRegularize('regularize')} variant="contained" sx={{ placeSelf: 'center', height: '31px', textTransform: 'none' }}  size="small">Request 1-on-1</Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '34%' }} className='performDonutWid' >
                        <span style={{ fontSize: '18px', fontWeight: '600' }}>Goal Progress</span>

                        <span onClick={this.showModal} style={{ color: '#2196f3', cursor: 'pointer', float: 'right' }}>How it works!</span>
                        <div style={{ marginTop: '35px' }} className=" mixed-chart">
                            <Chart options={this.state.options} series={this.state.series} type="donut" height={400} />
                        </div>
                    </div>

                </div>
                <div className='mt-4 d-flex'>
                    <div className='col p-2 performLineChart'>
                        <span style={{ fontSize: '18px', fontWeight: '600' }}>Average Progress</span>
                        <span style={{ float: 'right' }}><AntdTooltip title="Shows the average completion percentage across all the user’s goals. This indicates how close the user is to meeting all their targets.">
                            <i className="fa fa-info-circle" style={{ marginLeft: '8px', cursor: 'pointer' }}></i>
                        </AntdTooltip></span>
                        <LineChart width={600} height={200} data={data}
                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="linear" dataKey="progress" stroke="#82ca9d" strokeWidth={3} />
                        </LineChart>
                    </div>
                    <div className='ml-3 col performPendingPrev'>
                        <div className='myPendingAction'>
                            <div className='myPerform-miss-div'>
                                <span style={{ fontSize: '17px' }}>4</span> <br />
                                Missing
                            </div>
                            <span>Pending Action</span>
                            <span><Button variant="contained" sx={{ placeSelf: 'center', height: '31px', textTransform: 'none' }} size="small">View</Button></span>
                        </div>
                        <div className='prevPerformRate'>
                            <span className=''>Previous / Recent <br /> Performance Rating</span>
                            <span
                                className="perform-dash-gradestyle gnInfovalue"
                                style={{ backgroundColor: '#66db66' }}
                            >
                                5
                            </span>
                        </div>

                    </div>
                    <div className='ml-3 p-3 col performLineChart'>
                        <span style={{ fontSize: '18px', fontWeight: '600' }}>Progress Distribution</span>
                        <div style={{ lineHeight: '36px' }} className="progress-bars">
                            {progressData.map((item, index) => (
                                <div key={index} className="TeamPerform_progress-bar-container">
                                    <span className="TeamPerform_progress-label">{item.range}</span>
                                    <div className="TeamPerform_progress-container">
                                        <div className="TeamPerform_progress-bar" style={{ width: `${item.value}%`, background: 'grey' }}></div>
                                    </div>
                                    <span className="TeamPerform_progress-value">{item.count}</span>
                                </div>
                            ))}
                        </div>
                    </div>


                </div>
                <div>
                    <Modal
                        title="Automatic Status"
                        visible={this.state.isModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <span style={{ fontSize: '18px', fontWeight: '600' }}>How status is being calculated?</span>
                        <div className='goalStatusDis'>
                            <p className='p-2' style={{ borderBottom: 'solid 1px #d1cbcb' }}><span style={{ color: 'red', fontWeight: 600  }}>● At risk:</span> <span style={{ float: 'right' }} >  Expected Progress - Actual Progress &gt; 25% </span> </p>
                            <p className='p-2' style={{ borderBottom: 'solid 1px #d1cbcb' }}><span style={{ color: 'orange', fontWeight: 600  }}>● Needs attention:</span> <span style={{ float: 'right' }} > Expected Progress - Actual Progress &gt; 0% &amp; ≤ 25%</span> </p>
                            <p className='p-2 ' style={{ borderBottom: 'solid 1px #d1cbcb' }} ><span style={{ color: 'green', fontWeight: 600  }}>● On track:</span> <span style={{ float: 'right' }} > Expected Progress - Actual Progress ≤ 0%</span> </p>
                            <p className='p-2' style={{ borderBottom: 'solid 1px #d1cbcb' }}><span style={{ color: 'green', fontWeight: 600  }}>● Completed:</span> <span style={{ float: 'right' }} > Expected Progress - Actual Progress &gt; 100%</span> </p>
                            <p className='p-2 mb-0' ><span style={{ color: '#775dd0', fontWeight: 600  }}>● No Status:</span> <span style={{ float: 'right' }} > Expected Progress - Actual Progress &gt; 0%</span> </p>
                        </div>
                        <p><strong>Expected result:</strong> Expected progress is calculated based on the 'Start date' and 'End date' specified for a goal/sub goal.</p>
                    </Modal>
                </div>
            </div >
        )
    }
}
