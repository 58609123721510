import React from 'react';
import { Route, Switch } from 'react-router-dom';
import JobLanding from './Job';
import HJobFormView from './Job/ViewPage/HJobFormView';
import ApplicantLandingPage from './hApplicants';
import JobCandidateFormIndex from './hApplicants/hApplicantForm/JobCandidateFormIndex';
import ApplicantForm from './hApplicants/ApplicantForm/ApplicantForm';
import HApplicantViewPage from './hApplicants/AView/HApplicantViewPage';
const HireLanding = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/Job`} component={JobLanding} />
            <Route path={`${match.url}/viewForm/:id`} component={HJobFormView} />
            {/* Applicants */}
            <Route path={`${match.url}/Applicants`} component={ApplicantLandingPage} />
            {/* <Route path={`${match.url}/candidateinfo/:id/:sourcetype`} component={JobCandidateFormIndex} /> */}
            {/* Applciant Internal Form */}
            <Route path={`${match.url}/internalApplicantForm`} component={ApplicantForm} />
            <Route path={`${match.url}/applicantviewForm/:id`} component={HApplicantViewPage} />
        </Switch>
    )
};

export default HireLanding;
