

export const Country_List = [
  { id: 1, currency: "AED - United Arab Emirates", countryCode: "AE", minorUnit: "Fils" },
  { id: 2, currency: "AFN - Afghanistan", countryCode: "AF", minorUnit: "Pul" },
  { id: 3, currency: "XCD - Antigua and Barbuda", countryCode: "AG", minorUnit: "Cent" },
  { id: 4, currency: "ALL - Albania", countryCode: "AL", minorUnit: "Qindarkë" },
  { id: 5, currency: "AMD - Armenia", countryCode: "AM", minorUnit: "Luma" },
  { id: 6, currency: "ANG - Netherlands Antilles", countryCode: "AN", minorUnit: "Cent" },
  { id: 7, currency: "AOA - Angola", countryCode: "AO", minorUnit: "Cêntimo" },
  { id: 8, currency: "ARS - Argentina", countryCode: "AR", minorUnit: "Centavo" },
  { id: 9, currency: "AUD - Australia", countryCode: "AU", minorUnit: "Cent" },
  { id: 10, currency: "AWG - Aruba", countryCode: "AW", minorUnit: "Cent" },
  { id: 11, currency: "AZN - Azerbaijan", countryCode: "AZ", minorUnit: "Qəpik" },
  { id: 12, currency: "BAM - Bosnia and Herzegovina", countryCode: "BA", minorUnit: "Fening" },
  { id: 13, currency: "BBD - Barbados", countryCode: "BB", minorUnit: "Cent" },
  { id: 14, currency: "BDT - Bangladesh", countryCode: "BD", minorUnit: "Poisha" },
  { id: 15, currency: "BGN - Bulgaria", countryCode: "BG", minorUnit: "Stotinka" },
  { id: 16, currency: "BHD - Bahrain", countryCode: "BH", minorUnit: "Fils" },
  { id: 17, currency: "BIF - Burundi", countryCode: "BI", minorUnit: "Centime" },
  { id: 18, currency: "BMD - Bermuda", countryCode: "BM", minorUnit: "Cent" },
  { id: 19, currency: "BND - Brunei Darussalam", countryCode: "BN", minorUnit: "Sen" },
  { id: 20, currency: "BOB - Bolivia", countryCode: "BO", minorUnit: "Centavo" },
  { id: 21, currency: "BRL - Brazil", countryCode: "BR", minorUnit: "Centavo" },
  { id: 22, currency: "BSD - Bahamas", countryCode: "BS", minorUnit: "Cent" },
  { id: 23, currency: "BTN - Bhutan", countryCode: "BT", minorUnit: "Chetrum" },
  { id: 24, currency: "BWP - Botswana", countryCode: "BW", minorUnit: "Thebe" },
  { id: 25, currency: "BYN - Belarus", countryCode: "BY", minorUnit: "Kopek" },
  { id: 26, currency: "BZD - Belize", countryCode: "BZ", minorUnit: "Cent" },
  { id: 27, currency: "CAD - Canada", countryCode: "CA", minorUnit: "Cent" },
  { id: 28, currency: "CDF - Democratic Republic of the Congo", countryCode: "CD", minorUnit: "Centime" },
  { id: 29, currency: "CHF - Switzerland", countryCode: "CH", minorUnit: "Rappen" },
  { id: 30, currency: "CLP - Chile", countryCode: "CL", minorUnit: "Centavo" },
  { id: 31, currency: "CNY - China", countryCode: "CN", minorUnit: "Fen" },
  { id: 32, currency: "COP - Colombia", countryCode: "CO", minorUnit: "Centavo" },
  { id: 33, currency: "CRC - Costa Rica", countryCode: "CR", minorUnit: "Céntimo" },
  { id: 34, currency: "CUP - Cuba", countryCode: "CU", minorUnit: "Centavo" },
  { id: 35, currency: "CVE - Cabo Verde", countryCode: "CV", minorUnit: "Centavo" },
  { id: 36, currency: "CZK - Czechia", countryCode: "CZ", minorUnit: "Haléř" },
  { id: 37, currency: "DJF - Djibouti", countryCode: "DJ", minorUnit: "Centime" },
  { id: 38, currency: "DKK - Denmark", countryCode: "DK", minorUnit: "Øre" },
  { id: 39, currency: "DOP - Dominican Republic", countryCode: "DO", minorUnit: "Centavo" },
  { id: 40, currency: "DZD - Algeria", countryCode: "DZ", minorUnit: "Centime" },
  { id: 41, currency: "EGP - Egypt", countryCode: "EG", minorUnit: "Piastre" },
  { id: 42, currency: "ERN - Eritrea", countryCode: "ER", minorUnit: "Cent" },
  { id: 43, currency: "ETB - Ethiopia", countryCode: "ET", minorUnit: "Santim" },
  { id: 44, currency: "EUR - European Union", countryCode: "EU", minorUnit: "Cent" },
  { id: 45, currency: "FJD - Fiji", countryCode: "FJ", minorUnit: "Cent" },
  { id: 46, currency: "FKP - Falkland Islands (Malvinas)", countryCode: "FK", minorUnit: "Penny" },
  { id: 47, currency: "FOK - Faroe Islands", countryCode: "FO", minorUnit: "Øre" },
  { id: 48, currency: "GBP - United Kingdom", countryCode: "GB", minorUnit: "Penny" },
  { id: 49, currency: "GEL - Georgia", countryCode: "GE", minorUnit: "Tetri" },
  { id: 50, currency: "GGP - Guernsey", countryCode: "GG", minorUnit: "Penny" },
  { id: 51, currency: "GHS - Ghana", countryCode: "GH", minorUnit: "Pesewa" },
  { id: 52, currency: "GIP - Gibraltar", countryCode: "GI", minorUnit: "Penny" },
  { id: 53, currency: "GMD - Gambia", countryCode: "GM", minorUnit: "Butut" },
  { id: 54, currency: "GNF - Guinea", countryCode: "GN", minorUnit: "Centime" },
  { id: 55, currency: "GTQ - Guatemala", countryCode: "GT", minorUnit: "Centavo" },
  { id: 56, currency: "GYD - Guyana", countryCode: "GY", minorUnit: "Cent" },
  { id: 57, currency: "HKD - Hong Kong", countryCode: "HK", minorUnit: "Cent" },
  { id: 58, currency: "HNL - Honduras", countryCode: "HN", minorUnit: "Centavo" },
  { id: 59, currency: "HRK - Croatia", countryCode: "HR", minorUnit: "Lipa" },
  { id: 60, currency: "HTG - Haiti", countryCode: "HT", minorUnit: "Centime" },
  { id: 61, currency: "HUF - Hungary", countryCode: "HU", minorUnit: "Fillér" },
  { id: 62, currency: "IDR - Indonesia", countryCode: "ID", minorUnit: "Sen" },
  { id: 63, currency: "ILS - Israel", countryCode: "IL", minorUnit: "Agora" },
  { id: 64, currency: "IMP - Isle of Man", countryCode: "IM", minorUnit: "Penny" },
  { id: 65, currency: "INR - India", countryCode: "IN", minorUnit: "Paisa" },
  { id: 66, currency: "IQD - Iraq", countryCode: "IQ", minorUnit: "Fils" },
  { id: 67, currency: "IRR - Iran", countryCode: "IR", minorUnit: "Dinar" },
  { id: 68, currency: "ISK - Iceland", countryCode: "IS", minorUnit: "Eyrir" },
  { id: 69, currency: "JMD - Jamaica", countryCode: "JM", minorUnit: "Cent" },
  { id: 70, currency: "JOD - Jordan", countryCode: "JO", minorUnit: "Piastre" },
  { id: 71, currency: "JPY - Japan", countryCode: "JP", minorUnit: "Sen" },
  { id: 72, currency: "KES - Kenya", countryCode: "KE", minorUnit: "Cent" },
  { id: 73, currency: "KGS - Kyrgyzstan", countryCode: "KG", minorUnit: "Tyiyn" },
  { id: 74, currency: "KHR - Cambodia", countryCode: "KH", minorUnit: "Sen" },
  { id: 75, currency: "KID - Kiribati", countryCode: "KI", minorUnit: "Cent" },
  { id: 76, currency: "KMF - Comoros", countryCode: "KM", minorUnit: "Centime" },
  { id: 77, currency: "KPW - North Korea", countryCode: "KP", minorUnit: "Chon" },
  { id: 78, currency: "KRW - South Korea", countryCode: "KR", minorUnit: "Jeon" },
  { id: 79, currency: "KWD - Kuwait", countryCode: "KW", minorUnit: "Fils" },
  { id: 80, currency: "KYD - Cayman Islands", countryCode: "KY", minorUnit: "Cent" },
  { id: 81, currency: "KZT - Kazakhstan", countryCode: "KZ", minorUnit: "Tenge" },
  { id: 82, currency: "LAK - Laos", countryCode: "LA", minorUnit: "Att" },
  { id: 83, currency: "LBP - Lebanon", countryCode: "LB", minorUnit: "Piastre" },
  { id: 84, currency: "LKR - Sri Lanka", countryCode: "LK", minorUnit: "Cent" },
  { id: 85, currency: "LRD - Liberia", countryCode: "LR", minorUnit: "Cent" },
  { id: 86, currency: "LSL - Lesotho", countryCode: "LS", minorUnit: "Sente" },
  { id: 87, currency: "LYD - Libya", countryCode: "LY", minorUnit: "Dirham" },
  { id: 88, currency: "MAD - Morocco", countryCode: "MA", minorUnit: "Centime" },
  { id: 89, currency: "MDL - Moldova", countryCode: "MD", minorUnit: "Ban" },
  { id: 90, currency: "MGA - Madagascar", countryCode: "MG", minorUnit: "Iraimbilanja" },
  { id: 91, currency: "MKD - North Macedonia", countryCode: "MK", minorUnit: "Denar" },
  { id: 92, currency: "MMK - Myanmar", countryCode: "MM", minorUnit: "Pya" },
  { id: 93, currency: "MNT - Mongolia", countryCode: "MN", minorUnit: "Möngö" },
  { id: 94, currency: "MOP - Macau", countryCode: "MO", minorUnit: "Avo" },
  { id: 95, currency: "MRU - Mauritania", countryCode: "MR", minorUnit: "Khoums" },
  { id: 96, currency: "MUR - Mauritius", countryCode: "MU", minorUnit: "Cent" },
  { id: 97, currency: "MVR - Maldives", countryCode: "MV", minorUnit: "Laari" },
  { id: 98, currency: "MWK - Malawi", countryCode: "MW", minorUnit: "Tambala" },
  { id: 99, currency: "MXN - Mexico", countryCode: "MX", minorUnit: "Centavo" },
  { id: 100, currency: "MYR - Malaysia", countryCode: "MY", minorUnit: "Sen" },
  { id: 101, currency: "MZN - Mozambique", countryCode: "MZ", minorUnit: "Centavo" },
  { id: 102, currency: "NAD - Namibia", countryCode: "NA", minorUnit: "Cent" },
  { id: 103, currency: "NGN - Nigeria", countryCode: "NG", minorUnit: "Kobo" },
  { id: 104, currency: "NIO - Nicaragua", countryCode: "NI", minorUnit: "Centavo" },
  { id: 105, currency: "NOK - Norway", countryCode: "NO", minorUnit: "Øre" },
  { id: 106, currency: "NPR - Nepal", countryCode: "NP", minorUnit: "Paisa" },
  { id: 107, currency: "NZD - New Zealand", countryCode: "NZ", minorUnit: "Cent" },
  { id: 108, currency: "OMR - Oman", countryCode: "OM", minorUnit: "Baisa" },
  { id: 109, currency: "PAB - Panama", countryCode: "PA", minorUnit: "Centésimo" },
  { id: 110, currency: "PEN - Peru", countryCode: "PE", minorUnit: "Céntimo" },
  { id: 111, currency: "PGK - Papua New Guinea", countryCode: "PG", minorUnit: "Toea" },
  { id: 112, currency: "PHP - Philippines", countryCode: "PH", minorUnit: "Centavo" },
  { id: 113, currency: "PKR - Pakistan", countryCode: "PK", minorUnit: "Paisa" },
  { id: 114, currency: "PLN - Poland", countryCode: "PL", minorUnit: "Grosz" },
  { id: 115, currency: "PYG - Paraguay", countryCode: "PY", minorUnit: "Céntimo" },
  { id: 116, currency: "QAR - Qatar", countryCode: "QA", minorUnit: "Dirham" },
  { id: 117, currency: "RON - Romania", countryCode: "RO", minorUnit: "Ban" },
  { id: 118, currency: "RSD - Serbia", countryCode: "RS", minorUnit: "Para" },
  { id: 119, currency: "RUB - Russia", countryCode: "RU", minorUnit: "Kopek" },
  { id: 120, currency: "RWF - Rwanda", countryCode: "RW", minorUnit: "Centime" },
  { id: 121, currency: "SAR - Saudi Arabia", countryCode: "SA", minorUnit: "Halalah" },
  { id: 122, currency: "SBD - Solomon Islands", countryCode: "SB", minorUnit: "Cent" },
  { id: 123, currency: "SCR - Seychelles", countryCode: "SC", minorUnit: "Cent" },
  { id: 124, currency: "SDG - Sudan", countryCode: "SD", minorUnit: "Piastre" },
  { id: 125, currency: "SEK - Sweden", countryCode: "SE", minorUnit: "Öre" },
  { id: 126, currency: "SGD - Singapore", countryCode: "SG", minorUnit: "Cent" },
  { id: 127, currency: "SHP - Saint Helena, Ascension and Tristan da Cunha", countryCode: "SH", minorUnit: "Penny" },
  { id: 128, currency: "SLL - Sierra Leone", countryCode: "SL", minorUnit: "Cent" },
  { id: 129, currency: "SOS - Somalia", countryCode: "SO", minorUnit: "Cent" },
  { id: 130, currency: "SRD - Suriname", countryCode: "SR", minorUnit: "Cent" },
  { id: 131, currency: "SSP - South Sudan", countryCode: "SS", minorUnit: "Piastre" },
  { id: 132, currency: "STN - São Tomé and Príncipe", countryCode: "ST", minorUnit: "Cêntimo" },
  { id: 133, currency: "SYP - Syria", countryCode: "SY", minorUnit: "Piastre" },
  { id: 134, currency: "SZL - Eswatini", countryCode: "SZ", minorUnit: "Cent" },
  { id: 135, currency: "THB - Thailand", countryCode: "TH", minorUnit: "Satang" },
  { id: 136, currency: "TJS - Tajikistan", countryCode: "TJ", minorUnit: "Diram" },
  { id: 137, currency: "TMT - Turkmenistan", countryCode: "TM", minorUnit: "Tennesi" },
  { id: 138, currency: "TND - Tunisia", countryCode: "TN", minorUnit: "Millime" },
  { id: 139, currency: "TOP - Tonga", countryCode: "TO", minorUnit: "Seniti" },
  { id: 140, currency: "TRY - Turkey", countryCode: "TR", minorUnit: "Kuruş" },
  { id: 141, currency: "TTD - Trinidad and Tobago", countryCode: "TT", minorUnit: "Cent" },
  { id: 142, currency: "TWD - Taiwan", countryCode: "TW", minorUnit: "Cent" },
  { id: 143, currency: "TZS - Tanzania", countryCode: "TZ", minorUnit: "Cent" },
  { id: 144, currency: "UAH - Ukraine", countryCode: "UA", minorUnit: "Kopiyka" },
  { id: 145, currency: "UGX - Uganda", countryCode: "UG", minorUnit: "Cent" },
  { id: 146, currency: "USD - United States of America", countryCode: "US", minorUnit: "Cent" },
  { id: 147, currency: "UYU - Uruguay", countryCode: "UY", minorUnit: "Centésimo" },
  { id: 148, currency: "UZS - Uzbekistan", countryCode: "UZ", minorUnit: "Tiyin" },
  { id: 149, currency: "VES - Venezuela", countryCode: "VE", minorUnit: "Céntimo" },
  { id: 150, currency: "VND - Vietnam", countryCode: "VN", minorUnit: "Hào" },
  { id: 151, currency: "VUV - Vanuatu", countryCode: "VU", minorUnit: "Centime" },
  { id: 152, currency: "WST - Samoa", countryCode: "WS", minorUnit: "Sene" },
  { id: 153, currency: "XAF - Central African CFA franc", countryCode: "CF", minorUnit: "Centime" },
  { id: 154, currency: "XCD - East Caribbean dollar", countryCode: "AG", minorUnit: "Cent" },
  { id: 155, currency: "XDR - Special Drawing Rights", countryCode: "XF", minorUnit: "N/A" },
  { id: 156, currency: "XOF - West African CFA franc", countryCode: "CI", minorUnit: "Centime" },
  { id: 157, currency: "XPF - French Pacific franc", countryCode: "PF", minorUnit: "Centime" },
  { id: 158, currency: "YER - Yemen", countryCode: "YE", minorUnit: "Fils" },
  { id: 159, currency: "ZAR - South African rand", countryCode: "ZA", minorUnit: "Cent" },
  { id: 160, currency: "ZMW - Zambian kwacha", countryCode: "ZM", minorUnit: "Ngwee" },
  { id: 161, currency: "ZWL - Zimbabwe", countryCode: "ZW", minorUnit: "Cent" }
];