import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import SurveyQuestionForm from '../QuestionsAnswer/questionForm';
import SurveyMessageTemplate from './SurveyMessageTemplate/SurveyMessageTemplate';
import SurveyParticipantList from './SurveyParticipant/surveyParticipant';
import SurveyForm from './form';
import { getSurveyById, publishSurvey } from './service';
import Settings from './settingsSurvey/settings';
import { Step, StepLabel, Stepper } from '@mui/material';
import { getCompanyName } from './SurveyMessageTemplate/service';
import { getUserType, verifyOrgLevelViewPermission } from '../../../utility';
import { getSurveySettings } from './settingsSurvey/service';
import { Button } from 'react-bootstrap';

export default class ManageActionsLanding extends Component {
    constructor(props) {
        super(props);
        let survey = props.location.state;
        this.state = {
            survey: survey || {
                name: "",
                id: 0,
                description: "",
                languageId: 1,
                surveyStatus: ''
            },
            activeTab: 'details',
            companyName: '',
            refreshPage: false,
            surveyData: [],
            surveySettings: {},
            disablePublish: false,
            activeTabIndex: 0,
        };
    }

    handleNextButtonClick = () => {
        const { activeTabIndex } = this.state;

        if (activeTabIndex === 2) {
            this.publishSurvey();
        } else {
            this.setState(prevState => ({
                activeTabIndex: prevState.activeTabIndex + 1,
            }));
        }
  
    };
    handleBackButtonClick = () => {
        const { activeTabIndex } = this.state;
        this.setState(prevState => ({
            activeTabIndex: prevState.activeTabIndex - 1,
        }));

    };
    handleTabClick = (index) => {
        this.setState({
            activeTabIndex: index,
        });
    };

    getSurveyById = (id) => {
        if (!id) {
            this.setState({ surveyData: [{ isPublished: false }] });
            return;
        }

        getSurveyById(id).then(res => {
            if (res.status === "OK") {
                this.setState({ surveyData: res.data });
            } else {
                this.setState({ surveyData: [{ isPublished: false }] });
            }
        });
    }
    publishSurvey = () => {
        const { id, name } = this.state.survey;
        confirmAlert({
            title: `Publish Survey`,
            message: 'Are you sure, you want to publish ' + name + '?',
            buttons: [
                {
                    className: "btn btn-danger",
                    label: 'Yes',
                    onClick: () => publishSurvey(id).then(res => {
                        if (res.status == "OK") {
                            toast.success(res.message);
                            this.setState({
                                refreshPage: true,
                            }, () => {
                                this.getSurveyById(this.state.survey.id);
                            })
                            this.redirectToList()
                        } else {
                            toast.error(res.message)
                        }
                    })
                        .catch(err => {
                            console.error(err);
                        })
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    setSurvey = (survey) => {
        this.setState({
            survey: survey,
        });
    }
    componentDidMount() {
        this.fetchCompanyName();
        this.getSurveySetting(this.state.survey.id)
    }

    redirectToList = () => {
        this.props.history.goBack();
    }

    getSurveySetting = (id) => {
        getSurveySettings(id).then(res => {

            if (res.status == 'OK') {
                this.setState({
                    surveySettings: res.data,
                })
            }
        });
    }

    hasExceededCurrentDateTime(id) {
        this.setState({
            survey: {
                id: id,
            }
        })
        this.getSurveySetting(id);
        const { surveySettings } = this.state;
        if (surveySettings !== null && surveySettings.length > 0) {
            const now = new Date();
            const [year, month, day] = surveySettings.endDate.split('-');
            const [hour, minute, second] = surveySettings.endTime.split(':');
            const endDateTime = new Date(year, month - 1, day, hour, minute, second);

            this.setState({
                disablePublish: true,
            })
        }
        this.setState({
            disablePublish: false,
        })
    }

    fetchCompanyName = () => {
        if (getUserType() == "SUPER_ADMIN") {
            this.setState({
                companyName: "Template Company Name"
            })
        }
        else {
            getCompanyName().then((res) => {
                if (res.status === "OK") {
                    this.setState({
                        companyName: res.data.name
                    })
                } else {
                    console.log(res.message);
                }
            })
                .catch((err) => {
                    console.log({ err });
                    toast.error("Error while saving message");
                });
        }
    }
    render() {
        const { survey, activeTab, companyName, disablePublish, activeTabIndex  } = this.state;
        const isRecordSaved = survey.id > 0;
        const disableTabs = !isRecordSaved;
        const hideMessageTemplate = !isRecordSaved;
        const surveyData = this.props.location.state;
        let buttonLabel = activeTabIndex === 2 ? 'Publish' : 'Next';
        let prevButton = 'Back';
        let buttonIcon = activeTabIndex === 2 ? <i className="fa fa-upload"></i> : null;
        const isSettingsTab = activeTabIndex === 2;
        const detailsTab = activeTabIndex === 0;
        const steps = [
            'Draft Survey',
            'Invitee',
            'Schedule',
        ];

        return (
            <div className="page-wrapper">
                <div className="pb-4 p-5 content container-fluid">
                    <div className="surveyFormBody tab-content">
                        <div className="">
                            <div className="row user-tabs">
                                <div style={{ borderRadius: '10px 10px 0px 0px', height: '64px' }} className="card tab-box tab-position">
                                    <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                                        <h1 className="mt-2 modal-title">
                                            {activeTabIndex === 1
                                                ? "Manage Survey Participants"
                                                : activeTabIndex === 2
                                                    ? "Survey Timeline"
                                                    : "Survey Details"}</h1>
                                        <p className="ml-auto mb-3"  onClick={this.redirectToList}>
                                           
                                            <i className="surveyCloseBtn fa fa-times" aria-hidden="true"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3 mb-2'>
                            <Stepper alternativeLabel activeStep={this.state.activeTabIndex}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel style={{ marginTop: '1px' }} >{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div id="Details" className={`pro-overview tab-pane  fade ${activeTabIndex === 0 ? 'show active' : ''}`}>
                            <SurveyForm survey={survey} setSurvey={this.setSurvey} activeTab={activeTab} hideMessageTemplate={hideMessageTemplate} refreshPage={this.state.refreshPage} isPublished={this.state.surveyData.isPublished} />
                            {hideMessageTemplate ? null : <SurveyQuestionForm survey={survey} activeTab={activeTab} refreshPage={this.state.refreshPage} isPublished={this.state.surveyData.isPublished} />}
                            {hideMessageTemplate ? null : (companyName && <SurveyMessageTemplate survey={survey} activeTab={activeTab} companyName={companyName} surveyData={surveyData} refreshPage={this.state.refreshPage} isPublished={this.state.surveyData.isPublished} />)}
                        </div>
                        <div id="Invitee" className={`pro-overview tab-pane  fade ${disableTabs ? 'disabled' : ''} ${activeTabIndex === 1 ? 'show active' : ''}`}>
                            <SurveyParticipantList survey={survey.id}  surveyData={surveyData} />
                        </div>
                        <div id="Settings" className={`pro-overview tab-pane  fade ${disableTabs ? 'disabled' : ''} ${activeTabIndex === 2 ? 'show active' : ''}`}>
                            <Settings survey={survey.id} surveyData={surveyData} activeTab={activeTab} refreshPage={this.state.refreshPage} isPublished={this.state.surveyData.isPublished} hasExceededCurrentDateTime={this.hasExceededCurrentDateTime} />
                        </div>
                        <div className='surveyNavBtn'>
                            {!activeTabIndex == 0 && survey.surveyStatus == 'DRAFT' && getUserType() == 'COMPANY_ADMIN' && !hideMessageTemplate && (
                                <button
                                    type="button"
                                    className='p-0 survey_prevbtn btn btn-secondary'
                                    onClick={this.handleBackButtonClick}
                                    
                                >
                                    {prevButton}
                                </button>
                            )}
                            { survey.surveyStatus == 'DRAFT' && (getUserType() == 'COMPANY_ADMIN'|| verifyOrgLevelViewPermission("Survey")) && !hideMessageTemplate && (
                                <button
                                    type="button"
                                    className='survey_btn btn btn-success'
                                    onClick={this.handleNextButtonClick}
                                    disabled={isSettingsTab && disablePublish }
                                >
                                    {buttonIcon}
                                    {buttonLabel}
                                </button>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
