/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TeamApprovalLanding from './TeamApprovalLanding';


const TeamApprovalSetupRoute = ({ match }) => {
   return (
   <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */}
      <Route path={`${match.url}`} component={TeamApprovalLanding} /> 
   </Switch>
)};
export default TeamApprovalSetupRoute;
