/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import OvertimeApproval from './Overtime';
import PayrollLanding from './PaySlip/PayrollLanding';
import PayslipViewer from './PaySlip/view';
import PayVariance from './PayVariance';
import LeaveSettlement from './Settlement/LeaveSettlement';
import FinalSettlement from './Settlement/FinalSettlement';
import WorkExpensesRoute from './WorkExpenses/WorkExpensesRoute';
import DashboardOvertimeApproval from './Overtime/dashboardIndex';
import TeamTimeinlieu from '../MyEntitlements/TimeInLieu/dashboardIndex';
import EmployeePayrollProfile from './EmployeeProfile';
const PayrollRoute = ({ match }) => {
   return (
      <Switch>
         <Route path={`${match.url}/pay-slip`} component={PayrollLanding} />
         {/* <Route path={`${match.url}/overtime`} component={OvertimeApproval} />  */}
         <Route path={`${match.url}/dashboard-overtime`} component={DashboardOvertimeApproval} />
         <Route path={`${match.url}/dashboard-timeInlieu`} component={TeamTimeinlieu} />
         <Route path={`${match.url}/pay-variance`} component={PayVariance} />
         <Route path={`${match.url}/pay-slip-viewer`} component={PayslipViewer} />
         <Route path={`${match.url}/LeaveSettlement`} component={LeaveSettlement} />
         <Route path={`${match.url}/FinalSettlement`} component={FinalSettlement} />
         <Route path={`${match.url}/WorkExpenses`} component={WorkExpensesRoute} />
         <Route path={`${match.url}/payrollProfile`} component={EmployeePayrollProfile} />
      </Switch>
   )
};

export default PayrollRoute;
