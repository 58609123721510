import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, FormGroup } from 'reactstrap';
import EmployeeDropdown from '../ModuleSetup/Dropdown/EmployeeDropdown';
import { deleteOwners, getOwners, saveOwner } from './service';
import { getReadableDate, verifyOrgLevelViewPermission } from '../../utility';
import EmployeeListColumn from './employeeListColumn';
import { confirmAlert } from 'react-confirm-alert';
import AccessDenied from '../../MainPage/Main/Dashboard/AccessDenied';

toast.configure();
export default class OwnerForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editable: true,
            employeeId: '',
            owners: [],
            ownersData: {}
        }
    }
    componentDidMount() {
        this.fetchList();
    }

    fetchList = () => {
        if(verifyOrgLevelViewPermission("Module Setup Owner")){
        getOwners().then(res => {
            if (res.status === 'OK') {
                this.setState({
                    owners: res.data,
                });
            } else {
                console.log("Error: " + res.error);
            }
        }).catch(error => { console.log("Error: " + error); });
    };
    }

    save = () => {
        this.fetchList();
        saveOwner(this.state.employeeId).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
                this.fetchList();
            } else {
                toast.error(res.message);
            }
        }).catch(err => {
            toast.error("Please select the Employee to add Owner");
        })
    }
    handleEmployeeIdChange = (e) => {
        const newValue = e.target.value;
        this.setState({ employeeId: newValue });
    }

    delete = (empId) => {
        confirmAlert({
            title: `Remove Owner`,
            message: 'Are you sure, you want to remove this Owner?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteOwners(empId).then(res => {
                        if (res.status == "OK") {
                            toast.success(res.message);
                            this.fetchList();
                        } else {
                            toast.error(res.message)
                        }
                    })
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    render() {
        const { editable, employeeId, owners } = this.state;
        return (
            <>
                <div className="page-container content container-fluid">
                    {/* Page Header */}
                    <div className="tablePage-header">
                        <div className="row pageTitle-section">
                            <div className="col">
                                <h3 className="tablePage-title"> Manage Owners </h3>
                            </div>
                        </div>
                    </div>

                    {/* /Page Header */}
                    <div className="row">
                    {verifyOrgLevelViewPermission("Module Setup Owner") &&
                        <div className="col-md-12">
                            <div className="card-body">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={this.state.ownersData}
                                    onSubmit={this.save}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        setSubmitting
                                        /* and other goodies */
                                    }) => (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <label> Select Employee
                                                            <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <Field readOnly={editable} name="employeeId" className="form-control"
                                                            render={field => {
                                                                return <EmployeeDropdown
                                                                    nodefault={false}
                                                                    onChange={this.handleEmployeeIdChange}
                                                                    value={this.state.employeeId}
                                                                ></EmployeeDropdown>
                                                            }}
                                                        ></Field>
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6" style={{ paddingBlock: '32px' }}>
                                                    <input type="submit" className="btn btn-primary" value="Save" />
                                                </div>
                                            </div>
                                        </Form>
                                    )
                                    }
                                </Formik>

                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="expireDocs-table">
                                            <table className="table">
                                                <thead >
                                                    <tr style={{ background: '#c4c4c4' }}>
                                                        <th>#</th>
                                                        <th>Owners</th>
                                                        <th>Created On</th>
                                                        <th>Created By</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {owners.map((item, index) => (
                                                        <tr key={`${item.empId}_${index}`} className="table-row">
                                                            <td className="table-column">{index + 1}</td>
                                                            <td className="table-column">
                                                                <EmployeeListColumn
                                                                    key={item.empId}
                                                                    id={item.empId}
                                                                    name={`${item.employeeName}`}
                                                                    employeeId={item.employeeId}
                                                                />
                                                            </td>
                                                            <td className="table-column">{getReadableDate(item.createdOn)}</td>
                                                            <td className="table-column">{item.createdBy}</td>
                                                            <td className="table-column">
                                                                <div className="dropdow">
                                                                    <>
                                                                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-bars"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <a className="dropdown-item" href="#" onClick={() => {
                                                                                this.delete(item.empId);
                                                                            }}>
                                                                            <i className="fa fa-user-times m-r-5"></i> Remove </a>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                      {!verifyOrgLevelViewPermission("Module Setup Owner") && <AccessDenied></AccessDenied>}
                    </div>
                </div>

            </>
        )
    }
}
