/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TicketActionSetupRoute from './ticket';


const SupportModuleSetupRoute = ({ match }) => {
   return (
   <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */}
     
      <Route path={`${match.url}/ticket`} component={TicketActionSetupRoute} /> 
     
   </Switch>
)};
export default SupportModuleSetupRoute;
