/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Gratuity from './Gratuity/index';
import TimeInLieu from './TimeInLieu/index';
import Leave from './Leave/index'


const EntitlementRoute = ({ match }) => {
   return (
   <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */}
     
      <Route path={`${match.url}/gratuity`} component={Gratuity} />
      <Route path={`${match.url}/time-in-lieu`} component={TimeInLieu} />
      <Route path={`${match.url}/leave`} component={Leave} />
      
     
   </Switch>
)};

export default EntitlementRoute;
