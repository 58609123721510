import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { CONSTANT } from '../../../constant';
import AccessDenied from '../../../MainPage/Main/Dashboard/AccessDenied';
import { getDefaultProfilePicture, getEmployeeId, getPermission, getReadableDate, getTitle, getUserType, verifyViewPermission } from '../../../utility';
import Academic from './academic';
import AddressDetailEmployeeForm from './addressDetailForm';
import AllowanceForm from './allowanceForm';
import BankAccountDetailEmployeeForm from './bankAccountDetailForm';
import CompanyDetailEmployeeForm from './companyDetailForm';
import DocumentDetailList from './documentDetailList';
import Experience from './experience';
import PersonalDetailEmployeeForm from './personalDetailForm';
import ProfessionalReference from './reference';
import ChangeStatus from './statusChange'
import SalaryDetailEmployeeForm from './salaryDetailForm';
import CustomizationForm from './customizationForm';
import { getPersonalInformation,getSalaryEmploymentStatusIdInformation,getEmployementStatusById } from './service';
import Skill from './skill';
import StatusDetailEmployeeForm from './statusDetailForm';
import BenefitsDetail from './benefits';
import SystemLog from './auditTrail';
import { employeeProfilePhotoURL } from '../../../HttpRequest';
import EmployeePhoto from '../employeePhoto';
import { PERMISSION_LEVEL } from '../../../Constant/enum';
import EmployeeProfilePhoto from '../widgetEmployeePhoto';
const isCompanyAdmin = getUserType() == 'COMPANY_ADMIN' || getPermission("Employee", "EDIT") == PERMISSION_LEVEL.ORGANIZATION;
export default class EmployeeDetail extends Component {
    constructor(props) {
        super(props);
        const employeeId = props.match.params.id === "0" ? getEmployeeId() : props.match.params.id;
        this.state = {
            id: employeeId,
            employee: props.employee || {
                id: employeeId,
                name: "",
                active: true
            },
            profileImg: getDefaultProfilePicture(),
            activeTab: 0,
            allowToViewDetails: isCompanyAdmin || employeeId == getEmployeeId(),
            employmentStatus : []
        }
    }
    componentDidMount() {
        this.fetchList(this.state.id);
        console.log(this.state.allowToViewDetails)
        if (!this.state.allowToViewDetails) {
            return;
        }
        getPersonalInformation(this.state.id).then(res => {
            let employee = res.data;
            if (res.status == "OK") {
                employee.dob = employee?.dob?.substr(0, 10);
                this.setState({ employee },
                    () => {
                        console.log({ employee })
                    })
            }
        });
    }
    fetchList = (id) => {
        getEmployementStatusById(id).then(res => {
            if (res.status == "OK") {
              
               this.setState({employmentStatus: res.data})
            }
        })
    }

    handelActiveTab = (index) => {

        this.setState({ activeTab: index },
            () => {
                console.log({ index })
            })
    }
    render() {
        const { employee, activeTab, allowToViewDetails } = this.state;
        return (
            <div className="insidePageDiv">
                <Helmet>
                    <title>Employee Detail | {getTitle()}</title>
                </Helmet>
                <div className="page-containerDocList content container-fluid">
                    <div className="tablePage-header">
                        <div className="row pageTitle-section">
                            <div className="col">
                                <h3 className="tablePage-title">Profile</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/app/main/dashboard">Dashboard</a></li>
                                    <li className="breadcrumb-item"><Link to={"/app/company-app/employee"}>Employee</Link></li>
                                    <li className="breadcrumb-item active">Profile</li>
                                </ul>
                            </div>



                        </div>
                    </div>
                    {/* /Page Header */}

                    <div className="card mb-0">
                        <div className="card-body">
                            {allowToViewDetails && <div className="row">
                                <div className="col-md-12">
                                    <div className="profile-view">
                                        <div className="profile-img-wrap">
                                            <div className="profile-img">
                                                <a href="#"> <EmployeeProfilePhoto id={employee.id} alt={employee.name}></EmployeeProfilePhoto></a>
                                            </div>
                                        </div>
                                        <div className="profile-basic">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <div className="profile-info-left">
                                                        <h3 className="user-name m-t-0 mb-0">{employee.name}</h3>
                                                        <small className="text-muted">{employee.division?.name ? employee.division?.name + "/" : " "} </small>
                                                        <small className="text-muted"> {employee.department?.name ? employee.department?.name : " "}</small>
                                                        <small className="text-muted"> {employee.grades?.name ? " / " + employee.grades?.name : " "}</small>
                                                        <small className="text-muted"> {employee.branch?.name ? " / " + employee.branch?.name : " "} </small>
                                                        <small className="text-muted"> {employee.jobTitle?.name ? " / " + employee.jobTitle?.name : " "}</small>
                                                        <div className="staff-id">Employee ID : {employee.employeeId}</div>
                                                        <div className="small doj text-muted">Date of Join : {getReadableDate(employee?.doj) ? getReadableDate(employee?.doj) : "-"}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <ul className="personal-info">
                                                        <li>
                                                            <div className="title">Phone:</div>
                                                            <div className="text">{employee.phone ? <a href={'tel:' + employee.phone}>{employee.phone}</a> : "NA"} </div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Email:</div>
                                                            <div className="text">{employee.email ? <a href={'mailto:' + employee.email}>{employee.email}</a> : "NA"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Birthday:</div>
                                                            <div className="text">{employee.dob ? employee.dob : "NA"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Gender:</div>
                                                            <div className="text">{employee.gender ? employee.gender : "NA"}</div>
                                                        </li>
                                                        <li>
                                                            <div className="title">Blood Group:</div>
                                                            <div className="text">{employee.bloodGroup ? employee.bloodGroup : "NA"}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {!allowToViewDetails && <AccessDenied></AccessDenied>}
                        </div>
                    </div>

                    {allowToViewDetails && <div className="tab-content">
                        <div className="row user-tabs">
                            <div className="nav-box">
                                <div className="p-0 col-lg-12 col-md-12 col-sm-12 sub-nav-tabs">
                                    <ul className="nav nav-items">
                                        <li className="nav-item"><a href="#emp_profile" data-toggle="tab" className="nav-link active">Profile</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(1) }}><a href="#emp_salary" data-toggle="tab" className="nav-link">Salary</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(2) }}><a href="#emp_doc" data-toggle="tab" className="nav-link">Documents</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(3) }}><a href="#emp_qualification" data-toggle="tab" className="nav-link">Qualification</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(4) }}><a href="#emp_skill" data-toggle="tab" className="nav-link">Skill</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(5) }}><a href="#emp_experiance" data-toggle="tab" className="nav-link">Experience</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(6) }}><a href="#emp_reference" data-toggle="tab" className="nav-link">Reference</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(7) }}><a href="#emp_benefits" data-toggle="tab" className="nav-link">Benefits</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(8) }}><a href="#status_change" data-toggle="tab" className="nav-link">Change Status</a></li>
                                        <li className="nav-item" onClick={() => { this.handelActiveTab(9) }}><a href="#system_log" data-toggle="tab" className="nav-link">Audit Trail</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="emp_profile" className="pro-overview tab-pane fade show active">
                            <div className="row">
                                <PersonalDetailEmployeeForm employeeId={this.state.id}></PersonalDetailEmployeeForm>
                                <CompanyDetailEmployeeForm employeeId={this.state.id}></CompanyDetailEmployeeForm>
                                <BankAccountDetailEmployeeForm employeeId={this.state.id}></BankAccountDetailEmployeeForm>
                                <StatusDetailEmployeeForm employeeId={this.state.id}></StatusDetailEmployeeForm>
                                <AddressDetailEmployeeForm employeeId={this.state.id}></AddressDetailEmployeeForm>
                            </div>
                        </div>
                        {(this.state.employmentStatus.modeOfPay == "yes" || this.state.employmentStatus.length == 0) && <div id="emp_salary" className="pro-overview  tab-pane fade">
                            {activeTab == 1   && <SalaryDetailEmployeeForm employeeId={this.state.id}></SalaryDetailEmployeeForm>}

                        </div> }
                       {this.state.employmentStatus.modeOfPay == "no" && <div id="emp_salary" className="pro-overview  tab-pane fade">
                            {activeTab == 1  && <CustomizationForm  employeeId={this.state.id}></CustomizationForm>}


                        </div>}
                        <div id="emp_doc" className="pro-overview  tab-pane fade">
                            {activeTab == 2 && <DocumentDetailList employeeId={this.state.id}></DocumentDetailList>}

                        </div>
                        <div id="emp_qualification" className="pro-overview tab-pane fade">
                            {activeTab == 3 && <Academic employeeId={this.state.id}></Academic>}

                        </div>

                        <div id="emp_skill" className="pro-overview  tab-pane fade">
                            {activeTab == 4 && <Skill employeeId={this.state.id}></Skill>}

                        </div>

                        <div id="emp_experiance" className="pro-overview  tab-pane fade">
                            {activeTab == 5 && <Experience employeeId={this.state.id}></Experience>}
                        </div>

                        <div id="emp_reference" className="pro-overview  tab-pane fade">
                            {activeTab == 6 && <ProfessionalReference employeeId={this.state.id}></ProfessionalReference>}
                        </div>
                        <div id="status_change" className="pro-overview  tab-pane fade">
                            {activeTab == 8 && <ChangeStatus employeeId={this.state.id}></ChangeStatus>}
                        </div>
                        <div id="emp_benefits" className="pro-overview  tab-pane fade">
                            {activeTab == 7 && <BenefitsDetail employeeId={this.state.id}></BenefitsDetail>}
                        </div>
                        <div id="system_log" className="pro-overview tab-pane fade">
                            {activeTab == 9 && <SystemLog employeeId={this.state.id}></SystemLog>}
                        </div>
                    </div>}
                </div>

            </div>
        )
    }
}
