import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormGroup } from 'reactstrap';
import { verifyOrgLevelViewPermission, verifyOrgLevelEditPermission } from '../../../utility';
import { Country_List } from './countryList'
import AccessDenied from '../../../MainPage/Main/Dashboard/AccessDenied';
import Select from "react-select";
import { deleteCurrency, saveCurrencies } from './service';
import { confirmAlert } from 'react-confirm-alert';

toast.configure();
export default class CurrencySettingForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currencySetup: {
                id: 0,
                currency: "AED - United Arab Emirates",
                multiCurrency: "",
                active: false,
            },
            currency: Country_List,
            defaultCurrency: 'AE',
            selectedCurrencies: [],
        };
    }

    // componentDidMount() {
    //     this.fetchList();
    // }
    // fetchList = () => {
    //     getCurrencyList().then(res => {
    //         if (res.status == "OK") {
    //             this.setState({
    //                 data: res.data.list,
    //             })
    //         }
    //     })
    // }

    handleCurrency = (e, setFieldValue) => {

        const id = e.target.value;
        const currency = Country_List[id - 1];
        this.setState({ defaultCurrency: currency.countryCode });
        setFieldValue('currency', id);
    };
    handleMultiCurrency = (selectedOptions, setFieldValue) => {
        const selectedCurrencies = selectedOptions ? selectedOptions.map(option => option.value) : [];
        this.setState({ selectedCurrencies });
        setFieldValue('currency', selectedCurrencies);
    }

    delete = (currencyId) => {
        confirmAlert({
            title: `Remove Currency`,
            message: 'Are you sure, you want to remove this Currency?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteCurrency(currencyId).then(res => {
                        if (res.status == "OK") {
                            toast.success(res.message);
                            this.fetchList();
                        } else {
                            toast.error(res.message)
                        }
                    })
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    save = (data, action) => {
        action.setSubmitting(true);
        saveCurrencies(data).then(res => {
            if (res.status == "OK") {
                toast.success(res.message);
                this.props.updateList(res.data);
            } else {
                toast.error(res.message);
            }
            action.setSubmitting(false)
        }).catch(err => {
            toast.error("Error while saving currency");

            action.setSubmitting(false);
        })
    }

    render() {
        const { currency, defaultCurrency, selectedCurrencies, currencySetup } = this.state

        const currencyOptions = currency.map(cur => ({ value: cur.id, label: cur.currency }));

        const tableData = [
            { id: 1, currency: 'AED - United Arab Emirates', default: true },
            { id: 2, currency: 'AMD - Armenia', default: true },
            { id: 3, currency: 'EUR - European Union', default: true },
            { id: 4, currency: 'USD - United States of America', default: true },

        ]

        return (
            <>
                <div className="page-container content container-fluid">
                    {/* Page Header */}
                    <div className="tablePage-header">
                        <div className="row pageTitle-section">
                            <div className="col">
                                <h3 className="tablePage-title">Currency</h3>
                            </div>



                        </div>
                    </div>

                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card-body">
                                {verifyOrgLevelViewPermission("Module Setup Pay") && <Formik
                                    enableReinitialize={true}
                                    initialValues={this.state.currencySetup}
                                    onSubmit={this.save}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                        setSubmitting
                                        /* and other goodies */
                                    }) => (

                                        <Form>
                                            <div style={{ border: ' solid 1px #dddddd', padding: '12px', borderRadius: '4px' }}>
                                                <div className="row">
                                                    <div >
                                                        <FormGroup className='pl-0 col-md-6'>
                                                            <label>Default Currency
                                                                <span style={{ color: "red" }}>*</span>
                                                            </label>

                                                            <div className="currency-select-box">
                                                                <img src={`https://flagcdn.com/w320/${defaultCurrency.toLowerCase()}.png`} alt="Currency Flag" />
                                                                <select
                                                                    onChange={(e) => this.handleCurrency(e, setFieldValue)}
                                                                    name="currency"
                                                                    className="form-control"
                                                                    value={values.currency}
                                                                >
                                                                    {currency.map((cur, index) => (
                                                                        <option value={cur.id} key={index}> {cur.currency}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup >
                                                            <div type="checkbox" name="active" onClick={e => {
                                                                let { currencySetup } = this.state;
                                                                currencySetup.active = !currencySetup.active;
                                                                setFieldValue("active", currencySetup.active);
                                                                this.setState({
                                                                    currencySetup
                                                                });
                                                            }} >
                                                                <label>Add Multi Currency</label><br />
                                                                <i className={`fa fa-2x ${this.state.currencySetup
                                                                    && this.state.currencySetup.active
                                                                    ? 'fa-toggle-on text-success' :
                                                                    'fa fa-toggle-off text-danger'}`}></i>
                                                            </div>
                                                        </FormGroup>
                                                        {currencySetup.active && <FormGroup className="pl-0 col-md-8">
                                                            <label>Choose Multiple Currency
                                                                <span style={{ color: "red" }}>*</span>
                                                            </label>
                                                            <Select
                                                                isMulti
                                                                name="multiCurrency"
                                                                options={currencyOptions}
                                                                classNamePrefix="select"
                                                                onChange={(selectedOptions) => this.handleMultiCurrency(selectedOptions, setFieldValue)}
                                                                value={currencyOptions.filter(option => selectedCurrencies.includes(option.value))}
                                                            />


                                                        </FormGroup >}



                                                    </div>
                                                </div>
                                                <input type="submit" style={{ color: 'white', background: '#102746' }} className="btn" value="Save" />
                                            </div>
                                        </Form>
                                    )
                                    }
                                </Formik>}
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="expireDocs-table">
                                            <table className="table">
                                                <thead >
                                                    <tr style={{ background: '#c4c4c4' }}>
                                                        <th>#</th>
                                                        <th>Currency</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((item, index) => (
                                                        <tr key={`${item.empId}_${index}`} className="table-row">
                                                            <td className="table-column">{index + 1}</td>
                                                            <td className="table-column">
                                                                {item.currency}
                                                            </td>

                                                            <td className="table-column">
                                                                <div className="dropdow">
                                                                    <>
                                                                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                                            <i className="las la-bars"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <a className="dropdown-item" href="#" onClick={() => {
                                                                                this.delete(item.id);
                                                                            }}>
                                                                                <i className="fa fa-money"></i> Remove </a>
                                                                            <a className="dropdown-item" href="#" onClick={() => {
                                                                                this.makeAsDefault(item.id);
                                                                            }}>
                                                                                <i className="fa fa-check-circle-o"></i> Make as default </a>
                                                                        </div>

                                                                    </>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
