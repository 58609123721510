/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PerformanceReviewReportDetailsForm from './detailsForm'; 


const ReportDetailSetupRoute = ({ match }) => {
   return (
      <Switch>
         <Route path={`${match.url}/:id`} component={PerformanceReviewReportDetailsForm} />
      </Switch>
   )
};

export default ReportDetailSetupRoute;
