import React, { Component } from 'react';
import { getCustomizedWidgetDate } from '../../../../utility';
import '../attendanceCalendar.css';
import EmployeeListColumn from '../../employeeListColumn';
import EmployeePhoto from '../../employeePhoto';
import EmployeeDropdown from '../../../ModuleSetup/Dropdown/EmployeeDropdown';

export default class WeeklyCalendar extends Component {
    state = {
        currentDate: new Date(),
        today: new Date(),
    };

    getWeekDays = (date) => {
        const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
        const weekDays = [];
        for (let i = 0; i < 7; i++) {
            weekDays.push(new Date(startOfWeek));
            startOfWeek.setDate(startOfWeek.getDate() + 1);
        }
        return weekDays;
    };


    changeWeek = (direction) => {
        this.setState((prevState) => {
            const newDate = new Date(prevState.currentDate);
            newDate.setDate(newDate.getDate() + direction * 7);
            return { currentDate: newDate };
        });
    };

    isToday = (day) => {
        const { today } = this.state;
        return (
            day.getDate() === today.getDate() &&
            day.getMonth() === today.getMonth() &&
            day.getFullYear() === today.getFullYear()
        );
    };


    render() {
        const { currentDate } = this.state;
        const weekDays = this.getWeekDays(new Date(currentDate));

        const weeklyAttendance = [
            {
                Id: 15,
                name: "David Alex",
                employeeId: "EX000FD",
                weeklyData: [
                    { in: null, out: null, status: "weekoff" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: "9:15 AM", out: "5:15 PM", status: "Absent" },
                    { in: "9:00 AM", out: "5:00 PM", status: "present" },
                    { in: "9:30 AM", out: "5:30 PM", status: "Absent" },
                    { in: "9:30 AM", out: "5:30 PM", status: "Absent" },
                    { in: null, out: null, status: "weekoff" },
                ]
            },
            {
                Id: 16,
                name: "Sarah Smith",
                employeeId: "EX001FD",
                weeklyData: [
                    { in: "9:30 AM", out: "5:30 PM", status: "present" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: "9:15 AM", out: "5:15 PM", status: "Absent" },
                    { in: "9:00 AM", out: "5:00 PM", status: "present" },
                    { in: "9:30 AM", out: "5:30 PM", status: "present" },
                    { in: null, out: null, status: "weekoff" },
                    { in: null, out: null, status: "weekoff" }
                ]
            },
            {
                Id: 17,
                name: "John Doe",
                employeeId: "EX002FD",
                weeklyData: [
                    { in: "9:30 AM", out: "5:30 PM", status: "present" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: "9:15 AM", out: "5:15 PM", status: "Absent" },
                    { in: "9:00 AM", out: "5:00 PM", status: "present" },
                    { in: "9:30 AM", out: "5:30 PM", status: "present" },
                    { in: null, out: null, status: "weekoff" },
                    { in: null, out: null, status: "weekoff" }
                ]
            },
            {
                Id: 18,
                name: "Jhon Abraham",
                employeeId: "EX000FD",
                weeklyData: [
                    { in: null, out: null, status: "weekoff" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: "9:15 AM", out: "5:15 PM", status: "present" },
                    { in: "9:00 AM", out: "5:00 PM", status: "present" },
                    { in: null, out: null, },
                    { in: null, out: null, },
                    { in: null, out: null, status: "weekoff" },
                ]
            },
            {
                Id: 19,
                name: "Ajmal Ahmed",
                employeeId: "EX001FD",
                weeklyData: [
                    { in: null, out: null, status: "weekoff" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: "9:15 AM", out: "5:15 PM", status: "present" },
                    { in: "9:00 AM", out: "5:00 PM", status: "Absent" },
                    { in: "9:30 AM", out: "5:30 PM", status: "present" },
                    { in: "9:30 AM", out: "5:30 PM", status: "Absent" },
                    { in: null, out: null, status: "weekoff" },
                ]
            },
            {
                Id: 20,
                name: "Kareem",
                employeeId: "EX000FD",
                weeklyData: [
                    { in: null, out: null, status: "weekoff" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: null, out: null, },
                    { in: "9:00 AM", out: "5:00 PM", status: "present" },
                    { in: "9:30 AM", out: "5:30 PM", status: "Absent" },
                    { in: "9:30 AM", out: "5:30 PM", status: "Absent" },
                    { in: null, out: null, status: "weekoff" },
                ]
            },
            {
                Id: 21,
                name: "Fathima Sarah",
                employeeId: "EX001FD",
                weeklyData: [
                    { in: null, out: null, status: "weekoff" },
                    { in: "9:45 AM", out: "5:45 PM", status: "holiday" },
                    { in: "9:15 AM", out: "5:15 PM", status: "Absent" },
                    { in: null, out: null, },
                    { in: null, out: null, },
                    { in: "9:00 AM", out: "5:00 PM", status: "present" },
                    { in: null, out: null, status: "weekoff" }
                ]
            },
        ];

        console.log(weeklyAttendance);


        console.log(weeklyAttendance);


        return (
            <div>
                <div className='calendarDayChange_btn' >
                    <div className="mt-3 d-flex chart-list">
                        <div className="col-md-2">
                            <span className="badge weeklyCalendar-present p-2">Present</span>
                        </div>
                        <div className="col-md-2">
                            <span className="badge weeklyCalendar-absent p-2">Absent</span>
                        </div>
                        <div className="col-md-2">
                            <span className="badge weeklyCalendar-leave p-2">Leave</span>
                        </div>
                        <div className="col-md-2">
                            <span className="badge weeklyCalendar-week-off text-dark p-2">WeekOff</span>
                        </div>
                        <div className="col-md-2">
                            <span className="badge weeklyCalendar-holiday text-dark p-2">Holiday</span>
                        </div>
                        <div className="col-md-2">
                            <span className="badge weeklyCalendar-half-day text-dark p-2">Half Day</span>
                        </div>
                    </div>
                </div>

                <div className="weekly_tbl_cntnr ">
                    <div style={{ float: 'left', width: '25em' }} className="" >
                        <EmployeeDropdown nodefault={false} onChange={e => {
                            this.getListByEmployee(e.target.value)
                        }}></EmployeeDropdown>
                    </div>
                    <div className='weekSelectionTag_div'>
                        <span onClick={() => this.changeWeek(-1)} className='mychart_next' style={{ marginRight: '9px', fontSize: '19px' }}>
                            <i className='fa fa-caret-left' aria-hidden='true'></i>
                        </span>
                        <span className='weekSelectionTag'>
                            {getCustomizedWidgetDate(weekDays[0].toLocaleDateString())} -{' '}
                            {getCustomizedWidgetDate(weekDays[6].toLocaleDateString())}
                        </span>
                        <span onClick={() => this.changeWeek(1)} className='mychart_next' style={{ marginLeft: '9px', fontSize: '19px' }}>
                            <i className='fa fa-caret-right' aria-hidden='true'></i>
                        </span>
                    </div>
                    <div className="table-container" style={{ overflow: 'hidden' }}>
                        <table className="weeklyCalendar table table-bordered" style={{ borderRadius: '6px', borderBottom: 'none', tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr style={{ background: '#b7b7b7' }}>
                                    <th className='p-1 font-weight-bold' scope="col-md-2" style={{ width: '15%' }}>Name</th>
                                    {weekDays.map((day, index) => (
                                        <th key={index} className="p-1 text-center font-weight-bold" scope="col" style={{ width: '15%' }}>
                                            <div className={`calendar-day ${this.isToday(day) ? 'highlight-today' : ''}`}>
                                                <span>{day.toLocaleDateString('en-US', { weekday: 'short' })}</span>
                                                <span>{day.getDate()}</span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        </table>

                        {/* Scrollable tbody section */}
                        <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                            <table className="weeklyCalendar table table-bordered" style={{ borderRadius: '6px', borderTop: 'none', tableLayout: 'fixed', width: '100%' }}>
                                <tbody>
                                    {weeklyAttendance.map((item, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td scope="col" style={{  width: '15.4%' }}>
                                                <h2 style={{ wordSpacing: '-5px' }} className="table-avatar">
                                                    <div className="avatar">
                                                        <EmployeePhoto id={item.Id} alt={item.name}></EmployeePhoto>
                                                    </div>
                                                    <div>{item.name} <span style={{fontFamily: 'system-ui'}}>{item.employeeId}</span></div>
                                                </h2>
                                            </td>

                                            {item.weeklyData.map((data, colIndex) => (
                                                <td key={colIndex} scope="col-md-2" style={{
                                                     backgroundColor: this.isToday(weekDays[colIndex]) ? '#e0f5ff' : '',
                                                     textAlignLast: 'center', placeContent: 'center', width: '15%' }}>
                                                    {data.status === 'present' ? (
                                                        <div className="d-flex clockInOutTime_weeklyTbl">
                                                            <div><span>Clock-In</span><br />{data.in}</div>
                                                            <div className="clockInOut_weeklytbl"></div>
                                                            <div><span>Clock-Out</span><br />{data.out}</div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="weeklyBadgeAb"
                                                            style={{
                                                                background: data.status === 'Absent' ? '#fab9b1' :
                                                                    data.status === 'weekoff' ? '#b9bbb9' :
                                                                        data.status === 'holiday' ? '#f1eaa7' : '#b9cafa',
                                                                color: data.status === 'Absent' ? '#ac2a19' : '#000'
                                                            }}
                                                        >
                                                            {data.status === 'Absent' ? 'Absent' :
                                                                data.status === 'weekoff' ? 'WeekOff' :
                                                                    data.status === 'holiday' ? 'Holiday' : 'Leave'}
                                                        </div>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>



                </div>

            </div >
        );
    }
}

