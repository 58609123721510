import React, { Component } from 'react'
import { Card, Col, Row } from 'antd';
import Chart from "react-apexcharts";
import EmployeeProfilePhoto from '../../widgetEmployeePhoto';

export default class AttendanceMonthlyReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [25, 44, 30, 4],
            options: {
                chart: {
                    type: 'donut',
                },
                colors: ['#45C56D', '#4DC2DD', '#f88535', '#FF4040'],
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                dataLabels: {
                    enabled: false
                },
                labels: ['Present', 'Half Day', 'Leave', 'Absent'],
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 150,
                        },
                        legend: {
                            position: 'bottom',

                        }
                    }
                }]
            },

        }

    }



    render() {
        const attendanceReports = [
            { id: 1, name: "Ajith Dovel", jobtitle: "Finance Manager", employeeId: "EXPL001", present: 20, halfday: 4, leave: 2, absent: 2 },
            { id: 2, name: "Emma Watson", jobtitle: "Software Engineer", employeeId: "EXPL002", present: 18, halfday: 3, leave: 1, absent: 0 },
            { id: 3, name: "John Doe", jobtitle: "Marketing Specialist", employeeId: "EXPL003", present: 22, halfday: 2, leave: 0, absent: 1 },
            { id: 4, name: "Alice Johnson", jobtitle: "HR Manager", employeeId: "EXPL004", present: 19, halfday: 3, leave: 1, absent: 2 },
            { id: 15, name: "Michael Smith", jobtitle: "Product Manager", employeeId: "EXPL005", present: 21, halfday: 1, leave: 0, absent: 2 },
            { id: 16, name: "Sarah Parker", jobtitle: "Data Analyst", employeeId: "EXPL006", present: 17, halfday: 2, leave: 1, absent: 2 },
            { id: 17, name: "David Brown", jobtitle: "Sales Executive", employeeId: "EXPL007", present: 23, halfday: 1, leave: 0, absent: 0 },
            { id: 18, name: "Sophia Lee", jobtitle: "Operations Director", employeeId: "EXPL008", present: 20, halfday: 4, leave: 1, absent: 1 },
            { id: 19, name: "Robert Green", jobtitle: "Customer Support Specialist", employeeId: "EXPL009", present: 18, halfday: 3, leave: 1, absent: 2 },
            { id: 20, name: "Olivia Taylor", jobtitle: "UX Designer", employeeId: "EXPL010", present: 16, halfday: 2, leave: 2, absent: 1 }
        ];
        return (
            <div className='attendOrgCardSec'>
                <div style={{ width: '100%' }} className='row'>
                    {attendanceReports.map((item, index) => (
                        <Col style={{ marginBottom: '18px' }} key={index} xs={24} sm={12} md={12} lg={6}>
                            <Card style={{ height: '160px', width: '20em', border: 'solid 1px #dbcfcf', borderRadius: '5px' }} bodyStyle={{ padding: '1px' }} className='monthlyReportPie' bordered={false}>
                                <div className='monthlyReportCardTitle'>
                                    <div className="avatar">
                                        <EmployeeProfilePhoto id={item.id} alt={item.name}></EmployeeProfilePhoto>
                                    </div>
                                    <div style={{ display: 'grid' }}>
                                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.name} </span>
                                        <span style={{ fontSize: '12px', color: 'grey' }}>{item.employeeId}</span>
                                    </div>
                                </div>
                                <div>
                                    <Chart options={this.state.options} series={this.state.series} type="donut" width={250} />

                                </div>
                            </Card>
                        </Col>
                    ))}

                </div>
            </div>
        )
    }
}
