/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Template from '../CompanyApp/ModuleSetup/Template';
import CompanySettingModuleSetupRoute from '../CompanyApp/Settings';
import Company from './Company';
import Coupon from './Coupon';
import Order from './Order';
import Plan from './Plan';
import SMTP from './SMTP/form';
import SurveySetupRoute from '../CompanyApp/Survey';
import AccessModule from './Company/accessModule';
import CompanyDetailsLandingPage from './Company/CompanyDetailsLandingPage';
import CompanyCreateForm from './Company/companyCreateForm';
import PayHubLanding from './PayHub/landing';


const AdminAppRoute = ({ match }) => {
   return (
   <Switch>
      {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/admin`} /> */}
      <Route path={`${match.url}/company-add`} component={CompanyCreateForm} />
      <Route path={`${match.url}/company-details`} component={CompanyDetailsLandingPage} />
      <Route path={`${match.url}/company`} component={Company} />
      <Route path={`${match.url}/coupon`} component={Coupon} />
      <Route path={`${match.url}/order`} component={Order} />
      <Route path={`${match.url}/plan`} component={Plan} /> 
      <Route path={`${match.url}/smtp`} component={SMTP} />
      <Route path={`${match.url}/settings`} component={CompanySettingModuleSetupRoute} /> 
      <Route path={`${match.url}/module-setup/template`} component={Template} />
      <Route path={`${match.url}/manage-survey`} component={SurveySetupRoute} /> 
      <Route path={`${match.url}/access-module`} component={AccessModule} />
      <Route path={`${match.url}/payHub`} component={PayHubLanding} />
   </Switch>
)};

export default AdminAppRoute;
