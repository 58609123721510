/**
 * Crm Routes
 */
/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EmployeeReport from './Employee';
import companyanalyticsdashboard  from '../../MainPage/Main/Dashboard/companyanalyticsdashboard';
import ChatbotDashboard from '../ChatbotDashboard/chatbotDashboard';
import HireReportLanding from './Hire';
import PayReportLanding from './Pay';
import ManageReportLanding from './Manage';
import RewardReportLanding from './Reward';
import OnboardReportLanding from './Onboard';
import workForceplaneReportLanding from './PayReport';
import PerformReportLanding from './Perform';


const ReportRoute = ({ match }) => {
   return (
   <Switch>
      <Route path={`${match.url}/chatbotdashboard`} component={ChatbotDashboard} />
      <Route path={`${match.url}/employee`} component={EmployeeReport} />
      {/* <Route path={`${match.url}/payroll`} component={PayrollReport} /> */}
      <Route path={`${match.url}/plan`} component={workForceplaneReportLanding} />
      <Route path={`${match.url}/reward`} component={RewardReportLanding} />
      <Route path={`${match.url}/manage`} component={ManageReportLanding} />
      <Route path={`${match.url}/pay`} component={PayReportLanding} />
      <Route path={`${match.url}/hire`} component={HireReportLanding} />
      <Route path={`${match.url}/onboard`} component={OnboardReportLanding} />
      <Route path={`${match.url}/companydashboard`} component={companyanalyticsdashboard}/>
      <Route path={`${match.url}/perform`} component={PerformReportLanding} />
   </Switch>
)};

export default ReportRoute;
